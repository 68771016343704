<template>
    <div class="addInvoiceHeader">
        <van-nav-bar
            title="添加发票抬头"
            left-arrow
            @click-left="onClickLeft"
            fixed
            :z-index="100"
        />
        <div class="section">
            <div class="content">
                <van-form @submit="onSubmit">
                    <div class="content-text">
                        <van-cell-group inset>
                            <van-field name="radio" label="抬头类型" input-align="right" :rules="[{ required: true, message: '请选择抬头类型' }]">
                                <template #input >
                                    <van-radio-group v-model="formData.invoiceType" direction="horizontal" >
                                        <van-radio name="1">个人</van-radio>
                                        <van-radio name="2">企业</van-radio>
                                    </van-radio-group>
                                </template>
                            </van-field>

                            <van-field
                                input-align="right"
                                v-model="formData.invoice"
                                name="发票抬头"
                                label="发票抬头"
                                placeholder="请输入发票抬头"
                                :rules="[{ required: true}]"
                            />
                            <van-field
                                v-if="formData.invoiceType == 2"
                                input-align="right"
                                v-model="formData.taxPayer"
                                name="单位税号"
                                label="单位税号"
                                placeholder="请输入单位税号"
                                :rules="[{ required: true}]"
                            />
                            <van-field
                                input-align="right"
                                v-model="formData.receiverName"
                                name="收件人"
                                label="收件人"
                                placeholder="请输入收件人"
                                :rules="[{ required: true}]"
                            />
                            <van-field
                                input-align="right"
                                v-model="formData.receiverMobile"
                                :maxlength="11"
                                name="收件人电话"
                                label="收件人电话"
                                placeholder="请输入收件人电话"
                                :rules="[{ required: true}]"
                            />
                            <van-field
                                input-align="right"
                                v-model="formData.receiverEmail"
                                name="收件人邮箱"
                                label="收件人邮箱"
                                placeholder="请输入收件人邮箱"
                                :rules="[{ required: true}]"
                            />
                            
                            <van-field name="radio" label="设置默认" input-align="right">
                                <template #input >
                                    <van-radio-group v-model="formData.isDefult" direction="horizontal" >
                                        <van-radio name="0">取消默认</van-radio>
                                        <van-radio name="1">默认</van-radio>
                                        
                                    </van-radio-group>
                                </template>
                            </van-field>
                            <!-- <van-field
                                v-show="showOpen"
                                v-if="formData.invoiceType == 2"
                                input-align="right"
                                v-model="formData.bank"
                                name="开户银行"
                                label="开户银行"
                                placeholder="请输入开户银行/选填"
                            />
                            <van-field
                                v-show="showOpen"
                                v-if="formData.invoiceType == 2"
                                input-align="right"
                                v-model="formData.bankNumber"
                                name="银行账号"
                                label="银行账号"
                                placeholder="请输入银行账号/选填"
                            />
                            <van-field
                                v-show="showOpen"
                                v-if="formData.invoiceType == 2"
                                input-align="right"
                                v-model="formData.registeredAddress"
                                name="企业地址"
                                label="企业地址"
                                placeholder="请输入企业地址/选填"
                            />
                            <van-field
                                v-show="showOpen"
                                v-if="formData.invoiceType == 2"
                                input-align="right"
                                v-model="formData.registeredMobile"
                                name="企业电话"
                                label="企业电话"
                                placeholder="请输入企业电话/选填"
                            />
                            <van-field
                                v-show="showOpen"
                                input-align="right"
                                v-model="formData.remark"
                                rows="2"
                                autosize
                                label="备注"
                                type="textarea"
                                maxlength="200"
                                placeholder="请输入备注/选填"
                                show-word-limit
                            /> -->

                        </van-cell-group>
                        <!-- <div class="icon-img" @click="clickOpen" v-show="!showOpen">
                            <img src="@/assets/images/open.png" alt="">
                        </div>
                         <div class="icon-img" @click="clickPutAway" v-show="showOpen">
                            <img src="@/assets/images/putAway.png" alt="">
                        </div> -->
                        <div class="submitBtn">
                            <van-button round block type="danger" native-type="submit">
                                提交
                            </van-button>
                        </div>
                    </div>
                </van-form>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data(){
            return{
                formData:{
                    invoiceType: "1",//抬头类型
                    invoice:"",//发票抬头
                    taxPayer:"",//单位税号
                    receiverName:"",//收件人
                    receiverMobile:"",//收件人电话
                    receiverEmail:"",//收件人邮箱
                    // bank:"",//开户银行
                    // bankNumber:"",//银行账号
                    // registeredAddress:"",//企业地址
                    // registeredMobile:"",//企业电话
                    isDefult:"0",//默认
                },
                showOpen:false,
            }
        },
        methods:{
            // 返回
            onClickLeft() {
                this.$router.push("/controlOverInvoices")
            },
            // clickOpen(){
            //     this.showOpen = true;
            // },
            // clickPutAway(){
            //     this.showOpen = false;
            // }
            onSubmit(){
                let userId = localStorage.getItem("customerId");
                this.formData = {
                    ...this.formData,
                    userId:userId,
                }
                console.log(this.formData,"formData");
                // console.log("提交");
                this.$api.my.insetOrUpdate({
                    accessToken: localStorage.getItem("token"),
                    invoiceUserDO: JSON.stringify(this.formData),
                }).then((res) =>{
                    if (res.errno === 200) {
                        this.$toast("提交成功")
                        this.$router.push("/controlOverInvoices")
                    }else{
                        this.$toast("提交失败")
                    }
                    // console.log(res.errno);
                })
            }
        }
    }
</script>

<style lang="less" scoped>
@import "../../../assets/styles/element.less";
.addInvoiceHeader{
    width: 100vw;
    height: 100vh;
    background: #f7f7f7;
    // position: relative;
    .section {
        position: relative;
        top: 46px;
        height: calc(100vh - 46px);
        // display: flex;
        .content{
            width: 100%;
            position: relative;
            padding-top: .22rem /* 11/50 */;
            .content-text{
                height: 80vh;
                overflow-y: scroll;
                .icon-img{
                    height: .88rem /* 44/50 */;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: #fff;
                    margin-bottom: .4rem /* 20/50 */;
                    img{
                        width: .48rem /* 24/50 */;
                        height: .48rem /* 24/50 */;
                    }
                }
                .submitBtn{
                    position:fixed;
                    bottom: .2rem /* 10/50 */;
                    left: 0;
                    width: 91%;
                    margin: 0 .32rem /* 16/50 */ .32rem /* 16/50 */ .32rem /* 16/50 */;
                    .btn {
                        width: 100%;
                        .btnmin(
                            @padding:.24rem /* 12/50 */ 0,
                            @radius:.64rem /* 32/50 */,
                            @size:.32rem /* 16/50 */,
                            @bgcolor:@ordinary_btn_color,
                            @color:#fff
                        );
                    }
                }
            }
        }
    }
}
::v-deep .van-cell{
          left: 0;
          right: 0;
      .van-radio-group--horizontal{  
        .van-radio--horizontal{
          margin-right: 0;
          margin-left: .24rem /* 12/50 */;
        }
      }
      .van-field__label{
        color: #333;
      }
      .van-radio__icon--checked .van-icon{
        background-color: @radio_checked_color;
        border-color: @radio_checked_color;
      }
    }
    .van-button--danger{
        background-color: @ordinary_btn_color;
        border: 1px solid @ordinary_btn_border_color;
      }

</style>